import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="product-type-customization"
export default class extends Controller {
  static targets = ["cardAdvancedStyling", "radioButton"];

  connect() {
    const radioButtons = this.radioButtonTargets;
    const cardAdvancedStyling = this.cardAdvancedStylingTarget;

    let selectedValue;
    for (const radioButton of radioButtons) {
      if (radioButton.checked) {
        selectedValue = radioButton.value;
        break;
      }
    }
    cardAdvancedStyling.hidden = selectedValue === "basic";
  }

  setCustomization(event) {
    let target = event.target;
    const cardAdvancedStyling = this.cardAdvancedStylingTarget;

    cardAdvancedStyling.hidden = target.value === "basic";

    // Find out if editing existing record by rails hidden field present only in edit form
    let patchMethod = this.element.querySelector('input[type="hidden"][name="_method"]');
    // Make request only for existing record
    if (patchMethod && patchMethod.value === "patch") {
      let url = this.element.action; // read form action attr
      const formData = new FormData();
      formData.append("product_type[customization]", target.value);

      Rails.ajax({ type: "patch", url: url, data: formData });
    }
  }
}
