import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["fileInput", "image", "cropX", "cropY", "cropW", "cropH"];

  connect() {
    if (this.fileInputTarget.dataset.fileSizeExceeded !== "true") {
      const cropper = this.initCropper();
      this.addImageLoadListener(cropper);
    }
  }

  initCropper() {
    const image = this.imageTarget;
    const input_x = this.cropXTarget;
    const input_y = this.cropYTarget;
    const input_w = this.cropWTarget;
    const input_h = this.cropHTarget;

    return new Cropper(image, {
      autoCropArea: 1,
      zoomable: false,
      responsive: true,
      data: {
        x: parseFloat(input_x.value),
        y: parseFloat(input_y.value),
        width: parseFloat(input_w.value),
        height: parseFloat(input_h.value),
      },
      crop(event) {
        input_x.value = event.detail.x;
        input_y.value = event.detail.y;
        input_w.value = event.detail.width;
        input_h.value = event.detail.height;
      },
      cropend(event) {
        // Display hint
        const hintForSave = image.closest(".crop-group").querySelector("p.form-text");
        if (hintForSave) hintForSave.classList.add("d-block");
        image.closest("form").querySelector("input[type=submit]").classList.add("btn-glowing");
      },
    });
  }

  addImageLoadListener(cropper) {
    const fileInput = this.fileInputTarget;

    fileInput.addEventListener("change", function () {
      if (fileInput.files && fileInput.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
          cropper.replace(e.target.result);
        };

        reader.readAsDataURL(fileInput.files[0]);
      }

      // Display hint
      const hintForSave = fileInput.closest(".form-group").querySelector("p.form-text");
      if (hintForSave) hintForSave.classList.add("d-block");
      fileInput.closest("form").querySelector("input[type=submit]").classList.add("btn-glowing");
    });
  }
}
