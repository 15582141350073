import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="transparent-checkbox"
export default class extends Controller {
  static targets = ["checkbox", "colorField"];

  connect() {
    this.toggleColorPicker();
  }

  toggleColorPicker() {
    const checkbox = this.checkboxTarget;
    const colorField = this.colorFieldTarget;

    colorField.disabled = checkbox.checked;
  }
}
