import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company-header"
export default class extends Controller {
  static targets = ["showLink", "hideLink", "companyLogo", "webContactInfo"];

  connect() {
    this.updateElementsVisibility();
  }

  collapseToggle(event) {
    event.preventDefault();

    this.toggleWrapperElementClass();
    this.updateElementsVisibility();
  }

  // private

  toggleWrapperElementClass() {
    const companyHeader = this.element;
    companyHeader.classList.toggle("collapsed");
  }

  // display elements based on collapsed state
  updateElementsVisibility() {
    const collapseContent = this.element;
    const showLink = this.showLinkTarget;
    const hideLink = this.hideLinkTarget;
    const webContactInfo = this.webContactInfoTarget;

    if (this.hasCompanyLogoTarget) this.companyLogoTarget.classList.toggle("small");

    if (collapseContent.classList.contains("collapsed")) {
      showLink.hidden = false;
      hideLink.hidden = true;
      webContactInfo.hidden = false;
    } else {
      showLink.hidden = true;
      hideLink.hidden = false;
      webContactInfo.hidden = true;
    }
  }
}
