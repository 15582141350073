import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="customization-visibility"
export default class extends Controller {
  static targets = ["advancedStylingGroup"];

  advancedStylingGroupTargetConnected(element) {
    this.determineVisibility();
  }

  determineVisibility() {
    const advancedStylingGroups = this.advancedStylingGroupTargets;
    let selectedCustomization = document.querySelector('input[name="product_type[customization]"]:checked').value;

    advancedStylingGroups.forEach(function (group) {
      group.hidden = selectedCustomization === "basic";
    });
  }
}
