import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="on-page-edit"
export default class extends Controller {
  static targets = ["input", "output"];

  input_to_output() {
    this.outputTarget.value = this.inputTarget.value;
  }

  output_to_input() {
    this.inputTarget.value = this.outputTarget.value;
  }
}
