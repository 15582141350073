import { Controller } from "@hotwired/stimulus";
import Dropzone, { UPLOADING } from "dropzone";
import { DirectUpload } from "@rails/activestorage";
// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["input"];
  connect() {
    Dropzone.autoDiscover = false;
    this.inputTarget.disable = true;
    this.inputTarget.style.display = "none";
    const dropzone = new Dropzone(this.element, {
      url: "/",
      maxFiles: "10",
      maxFilesize: this.inputTarget.dataset.attachmentMaxMb,
      acceptedFiles: ".jpeg,.jpg,.png,.gif,.svg,.webp",
      autoQueue: false,
    });
    dropzone.on("addedfile", (file) => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url);
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input");
            this.hiddenInput.type = "hidden";
            this.hiddenInput.name = this.inputTarget.name;
            this.hiddenInput.value = blob.signed_id;
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling);
            dropzone.emit("success", file);
            dropzone.emit("complete", file);
          });
        }
      }, 500);
    });
  }
  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
}
