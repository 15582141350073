import { Controller } from "@hotwired/stimulus";
window.Pikaday = require("pikaday");

export default class extends Controller {
  connect() {
    let target = this.element;
    target.setAttribute("autocomplete", "off");
    target.setAttribute("autocorrect", "off");
    target.setAttribute("autocapitalize", "off");
    target.setAttribute("spellcheck", "false");

    new Pikaday({
      field: target,
      toString(date, format) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
      parse(dateString, format) {
        // dateString is the result of `toString` method
        const parts = dateString.split(".");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      },
      firstDay: 1,
      theme: "custom-theme",
    });
  }
}
