// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
import "@hotwired/turbo-rails";
require("@rails/activestorage").start();
require("channels");
import * as bootstrap from "bootstrap";

import "controllers";

// Trix Editor with custom toolbar
require("application/trix_custom");

// Errbit (Airbrake)

import { Notifier } from "@airbrake/browser";

window.jQuery = window.$ = require("jquery");

import Sortable from "sortablejs";
window.Sortable = Sortable;

import Rails from "@rails/ujs";
window.Rails = Rails;

import bsCustomFileInput from "bs-custom-file-input";
window.bsCustomFileInput = bsCustomFileInput;

import "@kollegorna/cocoon-vanilla-js";

// QrScanner requirements
require("file-loader");
import QrScanner from "qr-scanner";
window.QrScanner = QrScanner;

import QrScannerWorkerPath from "!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.initLandingPageItemsSortable = function () {
  var element = document.querySelector("#landing-page-items form");

  if (document.body.contains(element)) {
    var sortable = Sortable.create(element, {
      animation: 350,
      draggable: ".landing-page-item:not(.add-item)",
      handle: ".draggable",
      onUpdate: function (event) {
        Rails.fire(element, "submit");
      },
    });
  }
};

function submitForm(formElem) {
  Rails.fire(formElem, "submit");
  window.qrScanner.start(); // resume
}

window.setScannerStatus = function (status) {
  let statusWaitingElem = document.getElementById("scanner-status-waiting");
  let statusDoneElem = document.getElementById("scanner-status-done");

  if (statusWaitingElem == null || statusDoneElem == null) return;

  switch (status) {
    case "done":
      statusWaitingElem.classList.add("d-none");
      statusDoneElem.classList.remove("d-none");
      break;
    case "waiting":
      statusWaitingElem.classList.remove("d-none");
      statusDoneElem.classList.add("d-none");
      break;
  }
};

function initQrCodeScanner() {
  let videoElem = document.getElementById("scanner");
  if (!document.body.contains(videoElem)) return;

  let inputElem = document.getElementById("qr_code_url");
  window.qrScanner = new QrScanner(videoElem, (result) => {
    qrScanner.pause();

    inputElem.value = result;

    setScannerStatus("done");

    setTimeout(submitForm, 1000, inputElem.form);
  });
  qrScanner.start();
}

document.addEventListener("turbo:load", function () {
  initLandingPageItemsSortable();

  // Bootstrap custom file input
  bsCustomFileInput.init();

  // Bootstrap popovers
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  // QR Code Scanner
  initQrCodeScanner();
});

document.addEventListener("turbo:before-visit", function () {
  if (typeof window.qrScanner !== "undefined") {
    window.qrScanner.stop();
  }
});

if (
  process.env.RAILS_ENV !== "development" &&
  process.env.RAILS_ENV !== "test" &&
  process.env.RAILS_ENV !== "cypress"
) {
  const airbrake = new Notifier({
    host: ERRBIT_URL,
    remoteConfig: false,
    projectId: 1,
    projectKey: ERRBIT_PROJECT_KEY,
  });

  airbrake.addFilter((notice) => {
    notice.context.environment = process.env.RAILS_ENV;
    return notice;
  });
}

import "chartkick/chart.js"
