import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="read-more"
export default class extends Controller {
  static targets = ["shortText", "longText", "moreButton", "lessButton"];

  connect() {
    if (this.hasLongTextTarget) this.showLess();
  }

  showMore(event = null) {
    if (event) event.preventDefault();

    this.shortTextTarget.hidden = true;
    this.moreButtonTarget.hidden = true;
    this.longTextTarget.hidden = false;
    this.lessButtonTarget.hidden = false;
  }

  showLess(event = null) {
    if (event) event.preventDefault();

    this.shortTextTarget.hidden = false;
    this.moreButtonTarget.hidden = false;
    this.longTextTarget.hidden = true;
    this.lessButtonTarget.hidden = true;
  }
}
