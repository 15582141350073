import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="styling"
export default class extends Controller {
  static targets = ["tag"];
  static classes = ["active"];

  clicked(event) {
    this.tagTarget.classList.toggle(this.activeClass);
  }
}
