import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["appearanceSwitch", "customAppearanceField"];

  changed(event) {
    this.customAppearanceFieldTargets.forEach(field => {
      field.disabled = (event.target.value !== "custom")
    });
  }
}
