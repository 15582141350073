import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["vineyardContent"];

  toggleContent(event) {
    let target = event.target;
    const div = this.vineyardContentTarget;

    if (target.dataset.modulePlanName == "vgp_winemaker") {
      if (target.checked) {
        div.classList.remove("d-none");
      } else {
        div.classList.add("d-none");
      }
    }
  }
}
