import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput"];

  connect() {
    this.fileInputTarget.addEventListener('change', this.changeHandler);
  }

  changeHandler() {
    let maxMb = this.dataset.maxMb;

    if (maxMb === undefined)
      return;
    else
      maxMb = parseInt(maxMb);

    const label = this.parentElement.querySelector(".max-mb-message");

    if (label !== undefined)
      label.classList.remove('text-danger')

    if (this.files.length > 0) {
      const maxB = maxMb * (1024 ** 2);

      for (const file of this.files) {
        if (file.size > maxB) {
          this.value = null;
          this.dataset.fileSizeExceeded = true;
          if (label !== undefined)
            label.classList.add('text-danger');

          break;
        }
      }
    }
  }
}
