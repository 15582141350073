import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
// Connects to data-controller="tags"
export default class extends Controller {
  change() {
    let productCategoryId = event.target.value;
    let productTypeId = event.target.dataset.productTypeId;
    get(`/admin/product_categories/${productCategoryId}/tags?product_type_id=${productTypeId}`, {
      responseKind: "turbo-stream",
    });
  }
}
