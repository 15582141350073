import { Controller } from "@hotwired/stimulus";
import { gratitudeComponents } from "@3ipk/gratitude-shared-components";
import { renderNavDesktop } from "@3ipk/gratitude-shared-components/navbar";
import { renderFooter } from "@3ipk/gratitude-shared-components/footer";

// Connects to data-controller="gratitude-shared-components"
export default class extends Controller {
  static targets = ["navigation", "footer"];

  initialize() {
    console.log("GratitudeSharedComponentsController");
    const DEFAULT_LOCALE = "sk";
    const getCurrentLocale = () => {
      const params = new URLSearchParams(window.location.search)
      return params.has('lang') ? params.get('lang') : DEFAULT_LOCALE;
    };
    this.locale = getCurrentLocale();
    gratitudeComponents.setCustomFunctionOnLanguageClick((selectedLang) => {
      var url = new URL(window.location.href);
      url.searchParams.set('lang', selectedLang);
      window.location.assign(url.toString());
    });
    renderNavDesktop(this.locale);
    renderFooter(this.locale);
  }
}
