import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="visibility"
export default class extends Controller {
  static targets = ["hideable"];
  showTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = false;
    });
  }

  hideTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = true;
    });
  }

  toggleTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden;
    });
  }

  toggleClassTargets() {
    this.hideableTargets.forEach((el) => {
      el.classList.toggle("open");
    });
  }
}
